export interface Q {
  question: string;
  searchId: string;
  emoji: string;
}
export interface TagQs {
  tag: string;
  questions: Q[];
}

export const ANON_ID_FOR_SUGGESTED_QUERIES =
  '6de00561-61cf-4a95-bfc2-dfd83c812962';
export const SUGGESTED_QUERIES_TTL_IN_DAYS = 7;

export const defaultRecommendedDev: TagQs[] = [
  {
    tag: 'Market Research',
    questions: [
      {
        question: 'Market size of US SaaS companies with <10 employees',
        searchId: '706324dc-a05d-4c3f-bbea-7544b4542367',
        emoji: '💼',
      },
      // {
      //   question: 'London tech startups with <10 employees hiring engineers',
      //   searchId: '03ac01bd-5462-4723-9f1b-2a2c6a1435c3',
      //   emoji: '👨‍💻',
      // },
    ],
  },
  {
    tag: 'AI',
    questions: [
      {
        question: 'How does AI affect tik tok content?',
        searchId: '10421b18-ad4a-4e2e-b270-9fce61f7c3ba',
        emoji: '💻',
      },
      // {
      //   question: 'How likely is the government to block GPT5 from release?',
      //   searchId: '34efc92f-2d21-4427-bd2d-bef03319edf0',
      //   emoji: '🔒',
      // },
    ],
  },
  {
    tag: 'Current Events',
    questions: [
      // {
      //   question: 'How did NVIDIA take advantage of the AI gold rush?',
      //   searchId: 'dbbe64d6-606b-4582-8631-6dbdc8481f98',
      //   emoji: '📈',
      // },
      {
        question:
          'How likely is it for a World War 3 to happen in the next 3 years?',
        searchId: '3fc42830-1d1e-4323-8a21-bd1099302b18',
        emoji: '💣',
      },
    ],
  },
];

export const defaultRecommendedProd: TagQs[] = [
  {
    tag: 'Market Research',
    questions: [
      {
        question: 'Market size of US SaaS companies with <10 employees',
        searchId: '0ae41df2-9cf1-42bd-809e-260f76ac3fe9',
        emoji: '💼',
      },
      // {
      //   question: 'London tech startups with <10 employees hiring engineers',
      //   searchId: '65619461-ebbf-4c8c-b24b-0622baad2038',
      //   emoji: '👨‍💻',
      // },
    ],
  },
  {
    tag: 'AI',
    questions: [
      {
        question: 'How does AI affect tik tok content?',
        searchId: 'c41d3635-069f-4cc3-8467-48e653923041',
        emoji: '💻',
      },
      // {
      //   question: 'How likely is the government to block GPT5 from release?',
      //   searchId: 'f3b4daa2-44f8-4a27-93bf-25ae94108851',
      //   emoji: '🔒',
      // },
    ],
  },
  {
    tag: 'Current Events',
    questions: [
      // {
      //   question: 'How did NVIDIA take advantage of the AI gold rush?',
      //   searchId: 'e89c7bdf-d099-4841-8219-45dc51a4ed27',
      //   emoji: '📈',
      // },
      {
        question:
          'How likely is it for a World War 3 to happen in the next 3 years?',
        searchId: 'a51af6e0-f2f3-493a-a58a-4bcf7224ddfc',
        emoji: '💣',
      },
    ],
  },
];
