import style from './style.module.css';
import classNames from 'classnames';

interface Props {
  className?: string;
  fullScreen?: boolean;
}

export default function Spinner({ className = '', fullScreen = false }: Props) {
  return (
    <div
      className={classNames(
        `spinner`,
        { [style.fullScreen]: fullScreen },
        { [className]: Boolean(className) }
      )}
    >
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
}
