import { AnalyticsBrowser } from '@segment/analytics-next';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';
import { SearchLabel, ServerTypes } from 'lib/types';
import { Source } from 'providers/qnaAPI';
import { SourceTitleWithState } from 'providers/search';
import { SearchCompanion } from 'providers/searchCompanion';
import UAParser from 'ua-parser-js';
import { isDevOrLocalhost } from 'utils/helpers';

function isDefaultQuery(
  queryId: string | undefined,
  currRoute: string,
): boolean {
  if (!queryId) return false;
  const defaultRecommendedQs = isDevOrLocalhost(currRoute)
    ? defaultRecommendedDev
    : defaultRecommendedProd;
  const defaultQueryIds = defaultRecommendedQs
    .map((tq) => tq.questions.map((q) => q.searchId))
    .flat();
  return defaultQueryIds.includes(queryId);
}

export class Analytics {
  private segment: AnalyticsBrowser;

  constructor(writeKey: string) {
    this.segment = AnalyticsBrowser.load({ writeKey });
  }

  private getMeta() {
    const parser = new UAParser();
    const { device, os, browser } = parser.getResult();
    return {
      os: os.name,
      version: os.version,
      device: device.model,
      browser: browser.name,
    };
  }

  public getCleanUserData(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    if (!user) return { ...this.getMeta() };
    const name = `${user?.firstName} ${user?.lastName}`;
    return {
      email: user?.userName,
      name,
      userId: user?.id,
      sub: user?.authUserId,
      createdAt: user?.createdAt,
      profession: user?.professions?.[0]?.name,
      subscriptionDetails: user?.subscriptionDetails,
      ...this.getMeta(),
    };
  }

  public identify(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ): void {
    this.segment.identify(user?.id?.toString(), this.getCleanUserData(user), {
      Mixpanel: true,
    });
  }

  public track(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    eventName: string,
    properties: Record<string, unknown>,
  ) {
    this.identify(user);
    this.segment.track(
      eventName,
      { ...properties, ...this.getCleanUserData(user) },
      { Mixpanel: true },
    );
  }

  public page(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    category: string,
    pageName: string,
    properties: Record<string, unknown>,
  ) {
    this.segment.page(
      category,
      pageName,
      { ...properties, ...this.getCleanUserData(user) },
      { Mixpanel: true },
    );
  }

  // Page events

  // pages/client
  public viewedLanding(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const pageName = user ? 'Landing Page' : 'Landing Page (Anonymous)';
    const category = 'Landing Page';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // pages/search/client
  public viewedChatpage(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    searchCompanion: SearchCompanion | null,
    currRoute: string,
  ) {
    const sharedAnonymous = currRoute.includes('share=anonymous');
    const sharedUser = !sharedAnonymous && currRoute.includes('share=');
    const shareSuffix = sharedAnonymous
      ? '(Shared by Anonymous)'
      : sharedUser
        ? '(Shared by User)'
        : '';
    const pageName = `${user ? 'Chatpage' : 'Chatpage (Anonymous)'} ${shareSuffix}`;
    const category = 'Chatpage';
    const queryId = searchCompanion?.search.getQueryId(true);
    const tags = searchCompanion?.search.getTags(true);
    const sources = searchCompanion?.search.getSources(true);
    const isSuggestedQuery = isDefaultQuery(queryId, currRoute);
    const properties = {
      ...this.getCleanUserData(user),
      allQAs: searchCompanion?.search.getQuestionsAndAnswers(true),
      queryId: queryId,
      tags,
      isSuggestedQuery,
      sources,
    };
    this.page(user, category, pageName, properties);
  }

  // pages/create-account
  public viewedSignupPage() {
    const pageName = 'Sign up Page (Anonymous)';
    const user = null;
    const category = 'Authentication';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // pages/login
  public viewedSigninPage() {
    const pageName = 'Sign In Page (Anonymous)';
    const user = null;
    const category = 'Authentication';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // pages/post-registration
  public viewedPostRegistration(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const pageName = 'Post-Registration';
    const category = 'Authentication';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // Track events

  // Home/unauthFooter
  public clickedFooterLinkUnauth(link: string, title: string) {
    const eventName = 'Clicked Footer Link';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      link,
      title,
    };
    this.track(user, eventName, properties);
  }

  // recommendedQs/desktop, recommendedQs/questionBox
  public clickedSuggestedQuery(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    queryId: string,
    question: string,
    tag: string,
  ) {
    const eventName = user
      ? 'Clicked Suggested Query'
      : 'Clicked Suggested Query (Anonymous)';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      tag,
    };
    this.track(user, eventName, properties);
  }

  // Home/unauth
  public clickedHomeSearchboxUnauth() {
    const user = null;
    this.track(
      user,
      'Clicked Landing Searchbox (Anonymous)',
      this.getCleanUserData(user),
    );
  }

  // SearchBox
  public questionAsked(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    question: string | undefined,
  ) {
    const eventName = 'Question asked';
    const properties = {
      ...this.getCleanUserData(user),
      question,
    };
    this.track(user, eventName, properties);
  }

  // SearchBox
  public questionAskedAnonymous({
    question,
    anonymousId,
  }: {
    question: string | undefined;
    anonymousId: string | undefined;
  }) {
    const eventName = 'Question asked (Anonymous)';
    const properties = {
      question,
      anonymousId,
    };
    this.track(null, eventName, properties);
  }

  // providers/question
  public questionAnswered(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    question: string,
    queryId: string,
    tags: string[],
    sources: SourceTitleWithState[],
    answer: string,
  ) {
    const eventName = 'Question asked & answered';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      tags,
      sources,
      answer,
    };
    this.track(user, eventName, properties);
  }

  // providers/question
  public questionAnsweredAnonymous({
    question,
    queryId,
    tags,
    sources,
    answer,
    anonymousId,
  }: {
    question: string;
    queryId: string;
    tags: string[];
    sources: Source[];
    answer: string;
    anonymousId: string | undefined;
  }) {
    const eventName = 'Question asked & answered (Anonymous)';
    const properties = {
      question,
      queryId,
      tags,
      sources,
      answer,
      anonymousId,
    };
    this.track(null, eventName, properties);
  }

  // SearchContent
  public answerStopped(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    searchCompanion: SearchCompanion | null,
    question: string,
  ) {
    const eventName = 'Stopped Generating Answer';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId: searchCompanion?.search.getQueryId(),
      tags: searchCompanion?.search.getTags(),
      sources: searchCompanion?.search.getSources(),
    };
    this.track(user, eventName, properties);
  }

  public answerStoppedAnonymous({
    question,
    queryId,
    tags,
    sources,
    anonymousId,
  }: {
    question: string;
    queryId: string;
    tags: string[];
    sources: Source[];
    anonymousId: string | undefined;
  }) {
    const eventName = 'Stopped Generating Answer (Anonymous)';
    const properties = {
      question,
      queryId,
      tags,
      sources,
      anonymousId,
    };
    this.track(null, eventName, properties);
  }

  // SearchContent
  public answerRegenerated(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    searchCompanion: SearchCompanion | null,
    question: string,
  ) {
    const eventName = 'Regenerated Answer (Chatpage)';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId: searchCompanion?.search.getQueryId(),
      tags: searchCompanion?.search.getTags(),
      sources: searchCompanion?.search.getSources(),
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public answerRegeneratedAnonymous({
    question,
    queryId,
    tags,
    sources,
    answer,
    anonymousId,
  }: {
    question: string;
    queryId: string;
    tags: string[];
    sources: Source[];
    answer: string;
    anonymousId: string | undefined;
  }) {
    const eventName = 'Regenerated Answer (Chatpage)';
    const properties = {
      question,
      queryId,
      tags,
      sources,
      answer,
      anonymousId,
    };
    this.track(null, eventName, properties);
  }

  // SearchContent
  public answerRegeneratedSourceMgmt(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    searchCompanion: SearchCompanion,
    question: string,
  ) {
    const eventName = 'Regenerated Answer (Source Management)';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId: searchCompanion?.search.getQueryId(),
      tags: searchCompanion?.search.getTags(),
      sources: searchCompanion?.search.getSources(),
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public openedSourceMgmt(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    searchCompanion: SearchCompanion | null,
  ) {
    const eventName = 'Opened Source Management';
    const properties = {
      ...this.getCleanUserData(user),
      allSearchQnAs: searchCompanion?.search.getQuestionsAndAnswers(),
      queryId: searchCompanion?.search.getQueryId(),
      tags: searchCompanion?.search.getTags(),
      sources: searchCompanion?.search.getSources(),
    };
    this.track(user, eventName, properties);
  }

  public clickedEditSources({
    question,
    queryId,
    tags,
    sources,
    anonymousId,
  }: {
    question: string;
    queryId: string;
    tags: string[];
    sources: Source[];
    anonymousId: string | undefined;
  }) {
    const eventName = 'Clicked edit sources (Anonymous)';
    const properties = {
      question,
      queryId,
      tags,
      sources,
      anonymousId,
    };
    this.track(null, eventName, properties);
  }

  // providers/question
  public deletedSource(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    queryId: string,
    question: string,
    sourceTitle: string,
    sourceUrl: string,
  ) {
    const eventName = 'Deleted Source';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      sourceTitle,
      sourceUrl,
    };
    this.track(user, eventName, properties);
  }

  // providers/question
  public reenabledSource(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    queryId: string,
    question: string,
    sourceTitle: string,
    sourceUrl: string,
  ) {
    const eventName = 'Re-Enabled Source';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      sourceTitle,
      sourceUrl,
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public openedSource(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    searchCompanion: SearchCompanion | null,
    sourceTitle: string,
    url: string,
    location: string,
  ) {
    const eventName = 'Re-Enabled Source';
    const properties = {
      ...this.getCleanUserData(user),
      search: searchCompanion?.search,
      queryId: searchCompanion?.search.getQueryId(),
      sourceTitle,
      url,
      location,
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public shared(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    searchCompanion: SearchCompanion | null,
  ) {
    const eventName = user
      ? 'Shared Question Link'
      : 'Shared Question Link (Anonymous)';
    const properties = {
      ...this.getCleanUserData(user),
      allQAs: searchCompanion?.search.getQuestionsAndAnswers(),
      queryId: searchCompanion?.search.getQueryId(),
      tags: searchCompanion?.search.getTags(),
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public sharedAnonymous({
    question,
    queryId,
    tags,
    sources,
    answer,
    anonymousId,
  }: {
    question: string;
    queryId: string;
    tags: string[];
    sources: Source[];
    answer: string;
    anonymousId: string | undefined;
  }) {
    const eventName = 'Shared Question Link (Anonymous)';
    const properties = {
      question,
      queryId,
      tags,
      sources,
      answer,
      anonymousId,
    };
    this.track(null, eventName, properties);
  }

  // Sidebar/tagContainer
  public clickedPreviousSearch(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    prevSearch: SearchLabel,
  ) {
    const eventName = 'Clicked Previous Search';
    const properties = {
      ...this.getCleanUserData(user),
      title: prevSearch.title,
      queryId: prevSearch.queryId,
    };
    this.track(user, eventName, properties);
  }

  // ChatBox/auth
  public clickedDiscover(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    question: string,
  ) {
    const eventName = 'Clicked Discovery Button';
    const properties = {
      ...this.getCleanUserData(user),
      question,
    };
    this.track(user, eventName, properties);
  }

  // AccountSettingsModal
  public signedOut(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Signed Out';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedInEmail(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Signed In (Email)';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedInGoogle(
    user: Partial<ServerTypes.UserWithSubscriptionDetails>,
  ) {
    const eventName = 'Signed In (Google OAuth)';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/login
  public incorrectPassword(email: string) {
    const eventName = 'Incorrect Password on Sign in';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/createAccount
  public preverified(email: string) {
    const eventName = 'Signup - Entered Email and Password (Preverified)';
    const user = null;
    const properties = {
      email,
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedupEmail(user: Partial<ServerTypes.UserWithSubscriptionDetails>) {
    const eventName = 'Sign Up (Email) Success';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedupGoogle(
    user: Partial<ServerTypes.UserWithSubscriptionDetails>,
  ) {
    const eventName = 'Sign Up (Google OAuth) Success';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/verify
  public enteredCodeFailure(email: string) {
    const eventName = 'Entered Wrong Verification Code';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/verify
  public resendCodeEmail(email: string) {
    const eventName = 'Resent Verification Code';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/login
  public clickedForgotPassword(email: string) {
    const eventName = 'Clicked Forgot Password';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/password-reset
  public resetPassword(email: string) {
    const eventName = 'Reset Password Success';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public addedName(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Added First Name & Last Name';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public addedProfession(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Added Profession';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public skippedProfession(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Skipped Profession';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public invitedUser(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    inviteeEmails: string[],
  ) {
    const eventName = 'Invited Emails';
    const properties = {
      ...this.getCleanUserData(user),
      inviteeEmails,
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public skippedInviteUser(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Skipped Invited Emails';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public completedOnboarding(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Onboarding success';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // Sidebar
  public sharedInviteLink(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  ) {
    const eventName = 'Clicked copy/share invite link';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  public generic(
    user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    eventName: string,
    properties?: Record<string, unknown>,
  ) {
    this.track(user, eventName, {
      ...this.getCleanUserData(user),
      ...properties,
    });
  }
}

if (!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
  throw new Error(`NEXT_PUBLIC_SEGMENT_WRITE_KEY not set.`);
}

export const analytics = new Analytics(
  process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
);
