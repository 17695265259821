import { byQuestionMarkOrNewLine, specialCharacters } from './regex';

export const isDevOrLocalhost = (
  hostname: string = window.location.hostname,
) => {
  return hostname.startsWith('localhost') || hostname.startsWith('dev');
};

export const isValidFileSize = (file: File | null, maxFileSize = 10) => {
  if (file === null) {
    return false;
  }
  const fileSize = file.size;
  const maxBytes = maxFileSize * 1000000;
  return fileSize < maxBytes;
};

export const processBatchQuestions = (inputString: string) => {
  // Split the input string based on regex
  const questions = inputString.split(byQuestionMarkOrNewLine);
  if (questions.length === 1) return questions;

  // Remove special characters at the beginning and end of the questions and
  // Append a question mark back to each question
  const formattedQuestions = questions
    .map((question) => question.replace(specialCharacters, ''))
    .filter(Boolean)
    .map((q) => (!q.endsWith('.') ? q + ' ?' : q));

  return formattedQuestions;
};

export const getOriginalDocumentName = (fileName: string | null) => {
  if (typeof fileName !== 'string') {
    return '';
  }

  const parts = fileName.split('--');
  return parts[parts.length - 1];
};

export const sanitizeAndFormatFileName = (
  fileName: string,
  maxLength = 255,
) => {
  /** @info: Replace invalid characters with hyphens */
  let sanitizedFileName = fileName.replace(/[\/\\?%*:|"<> ]/g, '-');

  /** @info: Remove the first character if it's a hyphen */
  if (sanitizedFileName.charAt(0) === '-') {
    sanitizedFileName = sanitizedFileName.slice(1);
  }

  /** @info: Remove the last character if it's a hyphen */
  if (sanitizedFileName.charAt(sanitizedFileName.length - 1) === '-') {
    sanitizedFileName = sanitizedFileName.slice(
      0,
      sanitizedFileName.length - 1,
    );
  }

  /** @info: Convert to lowercase. Truncate if too long. */
  const truncatedFileName = sanitizedFileName.toLowerCase().slice(0, maxLength);

  return truncatedFileName;
};

export function capitalizeFirstLetter(str: string) {
  // Check if the string is not empty
  if (str.length === 0) {
    return str;
  }

  // Capitalize the first letter and concatenate with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}
